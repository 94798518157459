// islands/HtmlViewer.tsx
import { useEffect, useRef } from "preact/hooks";
import { urlSignal, anchorSignal } from "./signalStore.ts";

export default function HtmlViewer() {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    const scrollToAnchor = () => {
      const iframeDocument = iframe?.contentDocument || iframe?.contentWindow?.document;
      if (iframeDocument && anchorSignal.value) {
        const targetElement = iframeDocument.getElementById(anchorSignal.value);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    if (iframe) {
      iframe.addEventListener("load", scrollToAnchor);
      return () => {
        iframe.removeEventListener("load", scrollToAnchor);
      };
    }
  }, [urlSignal.value, anchorSignal.value]);

  return (
    <div class="h-full w-full">
 

      {urlSignal.value ? (
        <iframe
          ref={iframeRef}
          src={urlSignal.value}
          class="w-full h-full"  // Ensure the iframe takes the full width and height
          style={{ border: "none" }}  // Remove borders for a cleaner look
          title="HTML Viewer"
        />
      ) : (
        <div class="p-4 bg-gray-200 text-center">No URL to display</div>
      )}
    </div>
  );
}
